<script setup>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { useSystemStore } from './stores/system';
import { useAuthStore } from './stores/auth';
import { useDealerStore } from './stores/dealer';
import { useRoute } from 'vue-router';

const systemStore = useSystemStore();
const authStore = useAuthStore();
const dealerStore = useDealerStore();
</script>

<template>
  <Header />

  <div class="m-2 sm:m-12  min-h-[100vh]" :class="{'!max-h-[100vh] !min-h-[80vh] !m-4' : useRoute().name && useRoute().name.includes('View Lead')}">
    <router-view></router-view>

  </div>
    <div v-if="systemStore.loadingStatus" class="w-screen h-screen bg-black bg-opacity-20 fixed left-0 top-0 flex justify-center items-center">
      <svg class="animate-spin -ml-1 mr-3 h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="#14b981" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <Footer />

</template>

<style scoped>
</style>
