<script setup>
import { ref, onUnmounted } from 'vue';
import LoadingAnimation from '../LoadingAnimation.vue';
import { addRecord } from '../../services/garage_register_apis';
import { onMounted } from 'vue';

const props = defineProps({
    status: Boolean
})
const emit = defineEmits(['closePopup', 'closeRefresh'])

const new_record = ref({
    purchased_from_name: '',
    purchased_from_address: '',
    plate_no: null,
    odometer: null,
    year: null,
    make: null,
    model: null,
    style: null,
    vin: '',
    colour: '',
    in_stock_date: null,
    purpose: '',
    out_of_stock_date: null,
    new_owner_name: '',
    new_owner_address: '',
    stock_no: ''

})

const errors = ref({
    first_name: {
        status: false,
        message: ''
    },
    stock_no: {
        status: false,
        message: ''
    },
    in_stock_date: {
        status: false,
        message: ''
    },
    vin: {
        status: false,
        message: ''
    },
    purpose: {
        status: false,
        message: ''
    }
})

const requestError = ref(false);
const requestErrorMessage = ref('');
const submitButtonStatus = ref(false);
const recordCreated = ref(false);
const showPopup = ref(false);

onUnmounted(() => {
    new_record.value = {
        purchased_from_name: '',
        purchased_from_address: '',
        plate_no: null,
        odometer: null,
        year: null,
        make: null,
        model: null,
        style: null,
        vin: '',
        colour: '',
        in_stock_date: null,
        purpose: '',
        out_of_stock_date: null,
        new_owner_name: '',
        new_owner_address: '',
        stock_no: ''

    }
    submitButtonStatus.value = false;
    recordCreated.value = false;
})

onMounted(() => {
    setTimeout(() => {
        showPopup.value = true;
    }, 100);
})

function closePopup () {
    showPopup.value = false;

    setTimeout(() => {
        emit('closePopup');
    }, 500);

}
function validateFields() {
    errors.value = {
        first_name: {
            status: false,
            message: ''
        },
        stock_no: {
            status: false,
            message: ''
        },
        in_stock_date: {
            status: false,
            message: ''
        },
        vin: {
            status: false,
            message: ''
        },
        purpose: {
            status: false,
            message: ''
        }
    };

    const masterErrorStatus = ref(true);
    if (new_record.value.vin === '' || new_record.value.vin === null) {
        errors.value.vin.status = true;
        errors.value.vin.message = 'VIN is a required field'
        masterErrorStatus.value = false;
    }
    if (new_record.value.stock_no === '' || new_record.value.stock_no === null) {
        errors.value.stock_no.status = true;
        errors.value.stock_no.message = 'Stock No. is a required field'
        masterErrorStatus.value = false;
    }
    if (new_record.value.in_stock_date === '' || new_record.value.in_stock_date === null) {
        errors.value.in_stock_date.status = true;
        errors.value.in_stock_date.message = 'In-stock date is a required field'
        masterErrorStatus.value = false;
    }
    if (new_record.value.purpose === '' || new_record.value.purpose === null) {
        errors.value.purpose.status = true;
        errors.value.purpose.message = 'Purpose is a required field'
        masterErrorStatus.value = false;
    }
    return masterErrorStatus.value;

}

async function submitRecord() {
    if (validateFields()) {
        submitButtonStatus.value = true;
        const response = await addRecord(new_record.value);
        if (response?.status === 200) {
            submitButtonStatus.value = false;
            recordCreated.value = true;
            setTimeout(() => {
                emit('closeRefresh');
            }, 1500)
        }
    }
}
</script>
<template>
    <Transition>
        <div v-if="showPopup"
            class="fixed top-0 left-0 z-[500] bg-black bg-opacity-40 h-screen w-screen flex justify-center items-center">
            <div class="w-[625px] min-h-[40px] bg-white rounded-md p-6 grid">
                <div class="relative">
                    <div class="absolute right-0">
                        <i class="fa fa-close text-lg hover:bg-gray-400 px-2 rounded hover:text-white cursor-pointer"
                            v-on:click="closePopup"></i>
                    </div>
                </div>

                <div class="text-[24px] font-semibold">Add Garage Register Record</div>
                <div class="text-sm text-gray-600 mb-4">Fill out the information below to create a new contact. A
                    contact method (email or phone number) is required.</div>
                <div v-if="requestError" class="border border-red-500 bg-red-50 rounded p-2 my-2 text-sm ">
                    {{ requestErrorMessage }}</div>
                <div class=" grid grid-cols-1 my-2 gap-4">
                    <div class="grid grid-cols-3 gap-2">
                        <div class="flex flex-col justify-stretch items-stretch gap-1">
                            <label class="text-sm" for="stock_no">Stock No. <span class="text-red-500">*</span></label>
                            <input type="text" id="stock_no" v-model="new_record.stock_no" class="border px-2 rounded "
                                required :class="{ 'border-red-500': errors.stock_no.status }"
                                :disabled="submitButtonStatus" />
                            <span v-if="errors.stock_no.status" class="text-xs text-red-500">{{ errors.stock_no.message
                                }}</span>
                        </div>
                        <div class="flex flex-col justify-stretch items-stretch gap-1">
                            <label class="text-sm" for="in_stock_date">In-Stock Date <span
                                    class="text-red-500">*</span></label>
                            <input type="date" id="in_stock_date" v-model="new_record.in_stock_date"
                                class="border px-2 rounded " required
                                :class="{ 'border-red-500': errors.in_stock_date.status }"
                                :disabled="submitButtonStatus" />
                            <span v-if="errors.in_stock_date.status" class="text-xs text-red-500">{{
                                errors.in_stock_date.message }}</span>
                        </div>
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm">Out-of-Stock Date</label>
                            <input type="date" id="out_of_stock_date" v-model="new_record.out_of_stock_date"
                                class="border px-2 rounded" :disabled="submitButtonStatus" />
                        </div>
                    </div>
                    <div>
                        <div class="text-sm font-semibold">Used Motor Vehicle</div>
                    </div>
                    <div class="grid grid-cols-3 gap-2">
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="plate_no">Plate No.</label>
                            <input type="text" id="plate_no" class="border px-2 rounded" v-model="new_record.plate_no"
                                :disabled="submitButtonStatus" />
                        </div>
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="odometer">Odometer Reading</label>
                            <input type="text" id="odometer" v-model="new_record.odometer" class="border px-2 rounded"
                                :disabled="submitButtonStatus" />
                        </div>
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="colour">Colour</label>
                            <input type="text" id="colour" v-model="new_record.colour" class="border px-2 rounded"
                                :disabled="submitButtonStatus" />
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-2">
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="vehicle_year">Year</label>
                            <input type="text" class="border px-2 rounded" id="vehicle_year" v-model="new_record.year"
                                :disabled="submitButtonStatus" />
                        </div>
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="vehicle_make">Make</label>
                            <input type="text" class="border px-2 rounded" id="vehicle_make" v-model="new_record.make"
                                :disabled="submitButtonStatus" />
                        </div>
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="vehicle_model">Model <span class="text-[10px]">(Style in ON
                                    Garage
                                    Register)</span></label>
                            <input type="text" class="border px-2 rounded" id="vehicle_model" v-model="new_record.model"
                                :disabled="submitButtonStatus" />
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-2">
                        <div class="col-span-2 flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="vehicle_vin">V.I.N <span class="text-red-500">*</span></label>
                            <input type="text" class="border px-2 rounded" id="vehicle_vin" v-model="new_record.vin"
                                required :class="{ 'border-red-500': errors.vin.status }"
                                :disabled="submitButtonStatus" />
                            <span v-if="errors.vin.status" class="text-xs text-red-500">{{ errors.vin.message }}</span>
                        </div>
                        <div class="flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="purpose">Purpose <span class="text-red-500">*</span></label>
                            <select class="border px-2 rounded" id="purpose" required v-model="new_record.purpose"
                                :class="{ 'border-red-500': errors.purpose.status }" :disabled="submitButtonStatus">
                                <option value="">- Select -</option>
                                <option value="Resale">Resale</option>
                                <option value="Wrecking">Wrecking</option>
                                <option value="Consignment">Consignment</option>
                            </select>
                            <span v-if="errors.purpose.status" class="text-xs text-red-500">{{ errors.purpose.message
                                }}</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-5 gap-2">
                        <div class=" col-span-5 text-sm font-semibold">Purchased From</div>
                        <div class="col-span-2 flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="purchased_from_name">Previous Owner Name</label>
                            <input type="text" class="border px-2 rounded" id="purchased_from_name"
                                v-model="new_record.purchased_from_name" :disabled="submitButtonStatus" />
                        </div>
                        <div class="col-span-3 flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="purchased_from_address">Address <span class="text-[10px]">e.g.
                                    123 Street Address, Toronto, ON A1A 1A1</span></label>
                            <input type="text" id="purchased_from_address" v-model="new_record.purchased_from_address"
                                class="border px-2 rounded" :disabled="submitButtonStatus" />
                        </div>
                    </div>
                    <div class="grid grid-cols-5 gap-2">
                        <div class="col-span-5 text-sm font-semibold">Sold To</div>
                        <div class="col-span-2 flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="new_owner_name">New Owner Name</label>
                            <input type="text" class="border px-2 rounded" id="new_owner_name"
                                v-model="new_record.new_owner_name" :disabled="submitButtonStatus" />
                        </div>
                        <div class="col-span-3 flex flex-col items-stretch gap-1">
                            <label class="text-sm" for="new_order_address">Address <span class="text-[10px]">e.g.
                                    123 Street Address, Toronto, ON A1A 1A1</span></label>
                            <input type="text" id="new_order_address" class="border px-2 rounded"
                                v-model="new_record.new_owner_address" :disabled="submitButtonStatus" />
                        </div>
                    </div>
                    <div class="flex justify-end gap-3">
                        <button class="bg-gray-300 px-4 py-2 rounded-md text-black hover:bg-gray-500 hover:text-white"
                            v-on:click="closePopup">Close</button>
                        <button
                            class="bg-gr-green px-4 py-2 rounded-md text-white flex items-center disabled:bg-gray-300 disabled:text-white"
                            :disabled="submitButtonStatus" v-on:click="submitRecord">Add Record <i v-if="recordCreated"
                                class="fa fa-check ml-2"></i>
                            <LoadingAnimation v-if="submitButtonStatus" class="ml-2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>
<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>