<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import { useDealerStore } from '../stores/dealer'
import { computed } from 'vue';
const authStore = useAuthStore();
const dealerStore = useDealerStore();

const showSubMenu = ref(true);
const toggleMenu = () => {
  showSubMenu.value ? showSubMenu.value = false : showSubMenu.value = true;
}
const dealer_settings_loaded = ref(false)
dealerStore.$subscribe((mutations, state) => {
  if (state.dealerData.dealer_settings.dealer_limits) {
    dealer_settings_loaded.value = true
  } else {
    dealer_settings_loaded.value = false

  }
})
computed(() =>{
})
</script>
<template>
      <div class="sm:flex relative col-span-3 items-center justify-end text-white z-40">
        <div class=" w-16 mr-2 ">
          <img class="rounded" src="https://dealerswift.b-cdn.net/system/icons/holidy-icon.png" />
        </div>
        <div class="rounded bg-white text-[#00231f] py-1.5 px-3 hover:cursor-pointer" v-on:click="toggleMenu" >{{dealerStore.dealerData.dealer_name}} <i class="fa fa-chevron-down border-2 border-dashed p-0.5 ml-2 rounded border-[#00231f]"></i></div>
        <div class="absolute top-12 w-48  shadow rounded bg-white text-black p-2"    :class="{'hidden' : showSubMenu}">
          <div class="text-sm px-1 pb-2 border-b">Hi, {{ authStore.getUserData().firstname }}</div>
          <div class="text-sm px-1 my-1 pb-2 border-b"  v-if="dealer_settings_loaded">
            <div>Account Limits</div>
            <div class="text-xs flex justify-between my-1">
              <div>VIN Decoder</div>
              <div>
                <span>{{ dealerStore.dealerData.dealer_settings.dealer_limits.vin_decoder.available }}</span>
                <span>/</span>
                <span class="font-semibold text-green-700">{{ dealerStore.dealerData.dealer_settings.dealer_limits.vin_decoder.limit }}</span>
              </div>
            </div>
            <div class="text-xs flex justify-between my-1">
              <div>AI Description</div>
              <div>
                <span>{{ dealerStore.dealerData.dealer_settings.dealer_limits.description_ai.available }}</span>
                <span>/</span>
                <span class="font-semibold text-green-700">{{ dealerStore.dealerData.dealer_settings.dealer_limits.description_ai.limit }}</span>
              </div>
            </div>
          </div>
          <ul class="text-sm flex flex-col">
            <RouterLink to="/reports/garage-register" class="p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded" v-on:click="toggleMenu">Garage Register</RouterLink>
            <li class="hidden p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded">Profile Settings</li>
            <li class="hidden p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded">Users Management</li>
            <RouterLink to="/dealership-settings/" class="p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded">Dealership Settings</RouterLink>
            <li class="hidden p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded">Switch Accounts</li>
            <li class="hidden p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded">Billing</li>
            <li class="hidden p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded border-t border-b">Help</li>
            <li class="p-1 hover:bg-gray-100 cursor-pointer active:bg-gray-200 rounded" v-on:click="authStore.logOut()">Logout</li>
          </ul>
        </div>
      </div>
</template>