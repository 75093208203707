<script setup>
import { ref, onUnmounted, onMounted, watch } from 'vue';
import LoadingAnimation from '../LoadingAnimation.vue';
import { addRecord, deleteRecord, getRecord, updateRecord } from '../../services/garage_register_apis';

const props = defineProps({
    status: Boolean,
    id: Number
})
const emit = defineEmits(['closePopup', 'closeRefresh'])

const new_record = ref({
    purchased_from_name: '',
    purchased_from_address: '',
    plate_no: null,
    odometer: null,
    year: null,
    make: null,
    model: null,
    style: null,
    vin: '',
    colour: '',
    in_stock_date: null,
    purpose: '',
    out_of_stock_date: null,
    new_owner_name: '',
    new_owner_address: '',
    stock_no: ''

})


const requestError = ref(false);
const requestErrorMessage = ref('');
const submitButtonStatus = ref(false);
const recordDeleted = ref(false);
const id = ref(props.id)
watch(() => props.id, async (newValue, oldValue) => {
    if (props.id !== null) {
        const record = await getRecord(props.id);
        if (record?.status === 200) {
            new_record.value = record.data;
        }

    }
})

onMounted(async () => {
    if (props.id !== null) {
        const record = await getRecord(props.id);
        if (record?.status === 200) {
            new_record.value = record.data;
        }

    }

})
onUnmounted(() => {
    new_record.value = {
        purchased_from_name: '',
        purchased_from_address: '',
        plate_no: null,
        odometer: null,
        year: null,
        make: null,
        model: null,
        style: null,
        vin: '',
        colour: '',
        in_stock_date: null,
        purpose: '',
        out_of_stock_date: null,
        new_owner_name: '',
        new_owner_address: '',
        stock_no: ''

    }
    submitButtonStatus.value = false;
    recordDeleted.value = false;
})

async function submitRecord() {
    submitButtonStatus.value = true;
    try {

        const response = await deleteRecord(props.id);
        if (response?.status === 200) {
            submitButtonStatus.value = false;
            recordDeleted.value = true;
            setTimeout(() => {
                emit('closeRefresh');
            }, 1500)
        }
    } catch (error) {
        submitButtonStatus.value = false;
        requestError.value = true;
        requestErrorMessage.value = 'Sorry, we encountered an error while trying to delete the record. Please try again';

    }

}
</script>
<template>
    <Transition>
        <div v-if="props.status"
            class="fixed top-0 left-0 z-[500] bg-black bg-opacity-40 h-screen w-screen flex justify-center items-center">
            <div class="w-[625px] min-h-[40px] bg-white rounded-md p-6 grid">
                <div class="relative">
                    <div class="absolute right-0">
                        <i class="fa fa-close text-lg hover:bg-gray-400 px-2 rounded hover:text-white cursor-pointer"
                            v-on:click="emit('closePopup')"></i>
                    </div>
                </div>

                <div class="text-[24px] font-semibold">Delete Record for Stock No. {{ new_record.stock_no }}?</div>
                <div class="text-sm text-gray-600 mb-4">Please confirm that you would like to delete the Garage Register
                    record for the following vehicle.<strong class="text-red-500">This action is permenant and cannot be
                        undone</strong></div>
                <div class=" grid grid-cols-1 my-2 gap-4">
                    <div class="grid grid-cols-8 text-sm gap-y-1">
                        <div class="col-span-2 font-semibold">Vehicle:</div>
                        <div class="col-span-6">{{ new_record.year }} {{ new_record.make }} {{ new_record.model }}</div>
                        <div class="col-span-2 font-semibold">VIN:</div>
                        <div class="col-span-6">{{ new_record.vin }}</div>
                        <div class="col-span-2 font-semibold">Odometer:</div>
                        <div class="col-span-6">{{ new_record.odometer }}</div>
                        <div class="col-span-2 font-semibold">Purpose:</div>
                        <div class="col-span-6">{{ new_record.purpose }}</div>
                        <div class="col-span-2 font-semibold">Purchased From:</div>
                        <div class="col-span-6">{{ new_record.purchased_from_name + ' - ' +
                            new_record.purchased_from_address }}</div>
                        <div class="col-span-2 font-semibold">Sold To:</div>
                        <div class="col-span-6">{{ new_record.new_owner_name + ' - ' + new_record.new_owner_address }}
                        </div>
                    </div>

                    <div class="flex justify-end gap-3">
                        <button class="bg-gray-300 px-4 py-2 rounded-md text-black hover:bg-gray-500 hover:text-white"
                            v-on:click="emit('closePopup')">No, Keep Record</button>
                        <button
                            class="bg-red-500 px-4 py-2 rounded-md text-white flex items-center disabled:bg-gray-300 disabled:text-white"
                            :class="{'!bg-gr-green' : recordDeleted}"
                            :disabled="submitButtonStatus" v-on:click="submitRecord"><span v-if="!recordDeleted">Yes, Delete Record</span><span v-if="recordDeleted">Record Deleted!</span> <i
                                v-if="recordDeleted" class="fa fa-check ml-2"></i>
                            <LoadingAnimation v-if="submitButtonStatus" class="ml-2" />
                        </button>
                    </div>
                    <div v-if="requestError" class="border border-red-500 bg-red-50 rounded p-2 my-2 text-sm ">
                        {{ requestErrorMessage }}</div>

                </div>
            </div>
        </div>
    </Transition>
</template>
<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>