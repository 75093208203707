<script setup>
import { ref, onMounted } from 'vue';
import { getRecords } from '../../services/garage_register_apis.js'
import { useSystemStore } from '../../stores/system';
import AddRecordPopup from '../../components/GarageRegister/AddRecordPopup.vue';
import EditRecordPopup from '../../components/GarageRegister/EditRecordPopup.vue';
import DeleteRecordPopup from '../../components/GarageRegister/DeleteRecordPopup.vue';

// Stores
const systemStore = useSystemStore();

// Variables
const searchTerm = ref('');
const records = ref([]);
const meta = ref({})

const addRecordPopupStatus = ref(false);
const editRecordPopupStatus = ref(false);
const editRecordId = ref(null);
const deleteRecordPopupStatus = ref(false);
const deleteRecordId = ref(null)

// Lifecycle
onMounted(() => {
    loadGarageRegisterRecords();
})

// Functions
function triggerSearch() {

}

async function loadGarageRegisterRecords(page_number = 1) {
    if (page_number > 0) {

    systemStore.enableLoadingStatus();
    const records_data = await getRecords(page_number);
        if (records_data?.status === 200) {
            systemStore.disableLoadingStatus();
            records.value = records_data.data.data;
            meta.value = records_data.data.meta;
        } else {
            systemStore.disableLoadingStatus();
        }
    }

}

function toggleAddRecordPopup() {
    if (addRecordPopupStatus.value === false) {
        addRecordPopupStatus.value = true;
    } else {
        addRecordPopupStatus.value = false;
    }
}

function closeAndRefresh() {
    addRecordPopupStatus.value = false;
    editRecordPopupStatus.value = false;
    editRecordId.value = null;
    deleteRecordPopupStatus.value = false;
    deleteRecordId.value = null;

    loadGarageRegisterRecords(meta.value.page);
}

function toggleEditRecordPopup() {
    if (editRecordPopupStatus.value === false) {
        editRecordPopupStatus.value = true;
    } else {
        editRecordPopupStatus.value = false;
        editRecordId.value = null;
    }
}

function toggleDeleteRecordPopup() {
    if (deleteRecordPopupStatus.value === false) {
        deleteRecordPopupStatus.value = true;
    } else {
        deleteRecordPopupStatus.value = false;
        deleteRecordId.value = null;
    }
}

function openEditRecordPopup(record_id) {
    editRecordId.value = record_id;
    toggleEditRecordPopup();
}

function openDeleteRecordPopup(record_id) {
    deleteRecordId.value = record_id;
    toggleDeleteRecordPopup();
}

</script>
<template>
<h3 class="text-3xl font-bold">Garage Register</h3>

<div>
    <div class="my-2 flex flex-row justify-end	items-stretch">
        <div class="flex px-2 items-stretch">
            <input type="text" class="rounded px-2 w-72 text-xs" placeholder="Search by Name, Email, or Phone..." v-model="searchTerm" v-on:keyup="triggerSearch">
        </div>
        <button class="bg-gr-green px-4 py-2 rounded-md text-white text-sm" v-on:click="toggleAddRecordPopup()">Add A Record</button>
    </div>

</div>
<div class="border rounded-t-md flex flex-col ">
        <div class=" grid-cols-8 w-full bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4 hidden md:grid">
            <div>Stock #</div>
            <div class="">In-Stock Date</div>
            <div class="">VIN</div>
            <div class="">Vehicle</div>
            <div class="">Purchased From</div>
            <div class="">Out-of-Stock Date</div>
            <div class="">Sold To</div>
            <div></div>
        </div>
        <div v-for="record in records">
            <div class="grid grid-cols-8 gap-2 items-center w-full px-4 py-3 border-t text-sm bg-white border-l-gr-green border-l-2 hover:bg-gr-grid-top">
                <div>{{ record.stock_no }}</div>    
                <div>{{ record.in_stock_date }}</div>    
                <div>{{ record.vin }}</div>    
                <div>{{ record.year }} {{ record.make }} {{ record.model }}</div>    
                <div>{{ record.purchased_from_name }}</div>    
                <div>{{ record.out_of_stock_date }}</div>    
                <div>{{ record.new_owner_name }}</div>
                <div class="flex justify-end gap-2 text-sm">
                    <button class="border bg-white hover:border-gr-green hover:bg-gr-green hover:text-white px-2 py-1 rounded">View</button>
                    <button class="border bg-white hover:border-gr-green hover:bg-gr-green hover:text-white px-2 py-1 rounded" v-on:click="openEditRecordPopup(record.entry_id)">Edit</button>
                    <button class="border bg-white hover:border-red-500 hover:bg-red-500 hover:text-white px-2 py-1 rounded" v-on:click="openDeleteRecordPopup(record.entry_id)">Delete</button>
                </div>   
            </div>
        </div>
</div>
<div class="flex justify-end">
    <div class="my-2">
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <a href="#" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" :class="{'cursor-not-allowed' : meta.prev_page === null}" v-on:click="loadGarageRegisterRecords(meta.prev_page)">
          <span class="sr-only">Previous</span>
          <svg class="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
            <path fill-rule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
          </svg>
        </a>
        <div v-for="page in meta.total_pages">
            <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" :class="{'bg-gr-green !text-white hover:!bg-gr-green' : page === meta.page}" v-on:click="loadGarageRegisterRecords(page)">{{ page }}</a>
        </div>

        <a href="#" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"  :class="{'cursor-not-allowed' : meta.next_page === null}" v-on:click="loadGarageRegisterRecords(meta.next_page)">
          <span class="sr-only">Next</span>
          <svg class="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
            <path fill-rule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
          </svg>
        </a>
      </nav>
    </div>
</div>
<Transition>
    <div>
        <AddRecordPopup v-if="addRecordPopupStatus" :status="addRecordPopupStatus" @close-popup="toggleAddRecordPopup"  @close-refresh="closeAndRefresh" />
        <EditRecordPopup v-if="editRecordPopupStatus" :status="editRecordPopupStatus" :id="editRecordId" @close-popup="toggleEditRecordPopup"  @close-refresh="closeAndRefresh" />
        <DeleteRecordPopup v-if="deleteRecordPopupStatus" :status="deleteRecordPopupStatus" :id="deleteRecordId" @close-popup="toggleDeleteRecordPopup"  @close-refresh="closeAndRefresh" />
    </div>
</Transition>
</template>
<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>