'use strict';
import { useAuthStore } from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import axios from 'axios';
import { useVehiclesStore } from '../stores/vehicles';
import { useSystemStore } from '../stores/system';
import { watch } from 'vue';

const addRecord = async (data) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();

    // Ensure authStore and dealerStore data is valid
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        try {
            // Replace `newDealerId` with dealer ID from the dealerStore
            const dealerId = dealerStore.getDealerData().id;

            const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken()
                }
            };

            // Make the API call and await the response
            const response = await axios.post(
                `${apiEndpoint}/${dealerId}/garage-register/`,
                data,
                configs
            );

            // Return the response data
            return response.data;
        } catch (error) {
            // Handle errors
            console.error("Error adding record:", error);
            throw error; // Re-throw the error to handle it further up the chain
        }
    } else {
        throw new Error("Authorization failed or dealer data is missing.");
    }
};

const updateRecord = async (record_id, data) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();

    // Ensure authStore and dealerStore data is valid
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        try {
            // Replace `newDealerId` with dealer ID from the dealerStore
            const dealerId = dealerStore.getDealerData().id;

            const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken()
                }
            };

            // Make the API call and await the response
            const response = await axios.post(
                `${apiEndpoint}/${dealerId}/garage-register/${record_id}`,
                data,
                configs
            );

            // Return the response data
            return response.data;
        } catch (error) {
            // Handle errors
            console.error("Error adding record:", error);
            throw error; // Re-throw the error to handle it further up the chain
        }
    } else {
        throw new Error("Authorization failed or dealer data is missing.");
    }
};

const deleteRecord = async (record_id) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();

    // Ensure authStore and dealerStore data is valid
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        try {
            // Replace `newDealerId` with dealer ID from the dealerStore
            const dealerId = dealerStore.getDealerData().id;

            const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken()
                }
            };

            // Make the API call and await the response
            const response = await axios.delete(
                `${apiEndpoint}/${dealerId}/garage-register/${record_id}`,
                configs
            );

            // Return the response data
            return response.data;
        } catch (error) {
            // Handle errors
            console.error("Error deleting record:", error);
            throw error; // Re-throw the error to handle it further up the chain
        }
    } else {
        throw new Error("Authorization failed or dealer data is missing.");
    }
};

const getRecords = async (page_number) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const authStore = useAuthStore();
    const dealerStore = useDealerStore();

    // Return a Promise that resolves when both authStatus and dealerData.id are valid
    return new Promise((resolve, reject) => {
        // Watch for changes in both authStatus and dealerData.id
        const stopWatch = watch(
        () => [authStore.authStatus, dealerStore.getDealerData().id], // Watch both values
        async ([newAuthStatus, newDealerId]) => {
            // Proceed only if both authStatus is true and dealer id is available
            if (newAuthStatus && newDealerId) {
            try {
                const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken(),
                },
                };

                // Await the axios response
                const response = await axios.get(
                `${apiEndpoint}/${newDealerId}/garage-register/?page=${page_number}`,
                configs
                );

                stopWatch(); // Stop watching after we have a successful response
                resolve(response); // Resolve the Promise with the response data
            } catch (error) {
                console.error("Error fetching deal data:", error);
                stopWatch(); // Stop watching if there's an error
                reject(error.response || { status: 500, data: "Internal Server Error" }); // Reject the Promise with the error
            }
            }
        },
        { immediate: true } // This ensures the watcher runs immediately if conditions are already met
        );
    });
    
};

const getRecord = async (record_id) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();

    // Ensure authStore and dealerStore data is valid
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        try {
            // Replace `newDealerId` with dealer ID from the dealerStore
            const dealerId = dealerStore.getDealerData().id;

            const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken()
                }
            };

            // Make the API call and await the response
            const response = await axios.get(
                `${apiEndpoint}/${dealerId}/garage-register/${record_id}`,
                configs
            );

            // Return the response data
            return response.data;
        } catch (error) {
            // Handle errors
            console.error("Error getting record:", error);
            throw error; // Re-throw the error to handle it further up the chain
        }
    } else {
        throw new Error("Authorization failed or dealer data is missing.");
    }
};


export { addRecord, getRecords, getRecord, updateRecord, deleteRecord }